'use strict';

var Compare = require('core/product/compare');

var timer;
var tilesOnPage = 2;

var $compareBar = $('.compare-bar-wrapper');
var compareButtonText = $('button.compare').text();
var productsForComparison = [];
var maxSlots = parseInt($('.compare-bar').data('max-slots'), 10);

/**
 * Clears the Compare Bar and hides it
 * @return {undefined}
 */
 function clearCompareBar() {
    productsForComparison.forEach(function (product) {
        $(this).trigger('compare:deselected', { pid: product.pid });
    });

    productsForComparison = [];
    $('.compare-bar .product-slots').empty();
    
    $('.compare input').prop('checked', false);
    $('.compare input[type=checkbox]:not(:checked)').removeAttr('disabled');
    $compareBar.hide();
    
    window.location.replace(location.pathname);
}

/**
 * Compiles the HTML for a single slot
 *
 * @param {ProductComparisonList} product - Selected product to compare
 * @param {number} idx - Slot number (zero-based)
 * @return {string} - HTML for a single slot
 */
 function compileSlot(product, idx) {
    var pid = product.pid;
    var name = 'pid' + idx;

    return '' +
        `<div class="selected-product">
            <div class="slot selected-product__tile" data-pid="${pid}">
                <div class="selected-product__image">
                    <img src="${product.imgSrc}" />
                </div>
                <button type="button" class="close"></button>
                ${product.familyName ? '<div class="selected-product__family">' + product.familyName + '</div>' : ''}
                ${product.productName ? '<div class="selected-product__name">' + product.productName + '</div>' : ''}
                ${product.price ? '<div class="selected-product__price">' + product.price + '</div>' : ''}
            </div>
            <input type="hidden" name="${name}" value="${pid}" />
        </div>\n`;
}

/**
 * Enables/disables the Compare button, depending on whether at least two products have been
 * selected for comparison
 *
 * @param {number} numProducts - Number of products selected for comparison
 */
 function setCompareButton(numProducts) {
    if (numProducts > 0) {
        $('button.compare').text(compareButtonText + '(' + numProducts + '/4)');
    } else {
        $('button.compare').text(compareButtonText);
    }
    if (numProducts < 2) {
        $('button.compare').attr('disabled', true);
    } else {
        $('button.compare').removeAttr('disabled');
    }
}

/**
 * Draw and render the Compare Bar product slots
 *
 * @param {ProductComparisonList []} productsToCompare - List of ID's of the products to compare
 */
 function redrawCompareSlots(productsToCompare) {
    var html = productsToCompare.map(function (product, idx) {
        return compileSlot(product, idx);
    }).join('');
    $('.compare-bar .product-slots').empty().append(html);
}

/**
 * Returns a copy of a list of products to compare
 *
 * @param {ProductComparisonList []} productsToCompare - List of ID's of the products to compare
 * @return {ProductComparisonList []} List of ID's of the products to compare
 */
 function copyProducts(productsToCompare) {
    return productsToCompare.map(function (product) {
        var proxy = {};

        Object.keys(product).forEach(function (key) {
            proxy[key] = product[key];
        });

        return proxy;
    });
}

/**
 * Handles the selection of a product for comparison
 *
 * @param {ProductComparisonList []} products - List of ID's of the products to compare
 * @param {string} pid - ID for product to compare
 * @param {string} imgSrc - Image URL for selected product
 * @return {ProductComparisonList []} List of ID's of the products to compare
 */
function selectProduct(products, pid, imgSrc, productName, familyName, price) {
    var productsToCompare = copyProducts(products) || [];
    
    if (productsToCompare.length < maxSlots) {
        productsToCompare.push({
            pid: pid,
            imgSrc: imgSrc,
            productName: productName,
            familyName: familyName,
            price: price
        });

        if (productsToCompare.length === maxSlots) {
            $('input[type=checkbox]:not(:checked)').attr('disabled', true);
        }

        redrawCompareSlots(productsToCompare);
        setCompareButton(productsToCompare.length);
        $compareBar.show();
    }

    return productsToCompare;
}

/**
 * Handles the deselection of a product
 *
 * @param {ProductComparisonList []} products - List of ID's of the products to compare
 * @param {string} pid - ID for product to compare
 * @return {ProductComparisonList []} List of ID's of the products to compare
 */
 function deselectProduct(products, pid) {
    var productsToCompare = copyProducts(products) || [];

    productsToCompare = productsToCompare.filter(function (product) {
        return product.pid !== pid;
    });

    if (productsToCompare.length === 0) {
        $compareBar.hide();
    }

    $('input#' + pid).prop('checked', false);
    $('input[type=checkbox]:not(:checked)').removeAttr('disabled');

    redrawCompareSlots(productsToCompare);
    setCompareButton(productsToCompare.length);
    return productsToCompare;
}


/**
 * Moving table rows on mobile
 * @param {Object} $this - Slider arrow jQuery object
 * @param {Object} $tableRows - Table rows jQuery object
 * @param {Number} step - moving step number 
 */
function moveTableRow($this, $tableRows, step) {
    var translateValue = (100 / $('.js-compare-product-cell').length) * step;
    $tableRows.css('transform', 'translateX(-'+ translateValue +'%)');
    $this.closest('.js-compare-tile').data('step', step);
}

/**
 * Toggle slider arrows
 * @param {Number} productLength - compared product quantity
 * @param {Number} step - moving step number 
 * @param {Number} tilesOnPage - quantity of products showing on page on mobile
 */
function toggleArrows(productLength, step, tilesOnPage) {
    if (step === (productLength - tilesOnPage)) {
        $('.m-next').removeClass('m-visible').addClass('m-invisible');
        $('.m-prev').removeClass('m-invisible').addClass('m-visible');
    } else if (step === 0) {
        $('.m-prev').removeClass('m-visible').addClass('m-invisible');
        $('.m-next').removeClass('m-invisible').addClass('m-visible');
    } else {
        $('.m-next, .m-prev').removeClass('m-invisible').addClass('m-visible');
    }
}

/**
 * Redirecting after removing product
 * @param {String} productId - Product ID
 */
function changePageLocationAddress(productId) {
    var newSearchStr = '?' + window.location.search.substr(1).split('&').map((item) => {
        if(item.indexOf(productId) === -1) {
            return item;
        };
    }).filter(item => item !== undefined).join('&');

    location.href = (location.origin + location.pathname + newSearchStr);
}

/**
 * Mowing table after swiping on mobile
 * @param {Object} $this - Table row with product tiles
 * @param {Number} clientSwipeStart - swiping start position
 * @param {Number} clientSwipeEnd - swiping finish position
 */
function swipeTranslateTable($this, clientSwipeStart, clientSwipeEnd) {
    
    var containerWidth = parseInt($this.width());
    var tilesLength = $('.js-compare-product-cell').length;
    var step = parseInt($this.data('step'));
    
    var swipingPercentageStep = 100 / tilesLength;
    var tileWidth = containerWidth / tilesLength;
    var swipingLength = tileWidth - (tileWidth * 0.3);    
    var translateValue;

    if (clientSwipeEnd > clientSwipeStart && 
            Math.abs(clientSwipeEnd - clientSwipeStart) > swipingLength) 
        {
            step > 0 ? step-- : step;
            translateValue = swipingPercentageStep * step;            
        } else if (clientSwipeEnd < clientSwipeStart && 
            Math.abs(clientSwipeEnd - clientSwipeStart) > swipingLength) 
        {
            step < (tilesLength - tilesOnPage) ? step++ : step;
            translateValue = swipingPercentageStep * step;  
        }

        $this.data('step', step);
        checkBorder();
        function checkBorder() {
            $('.js-compare-table-row').css('transform', 'translateX(-'+ (translateValue) +'%)');
            
            $('.js-compare-table-row .js-compare-table-cell:not(".js-compare-product-cell")').eq(2).css('border-right', 'none');
            $('.js-compare-table-row .js-compare-table-cell:not(".js-compare-product-cell")').eq(3).css('border-left', '1px solid #979797');
            if (step === 0) {
                $('.js-compare-table-row .js-compare-table-cell:not(".js-compare-product-cell")').eq(1).css('border-right', '1px solid #979797');
            }
            if (step === 1) {
                $('.js-compare-table-row .js-compare-table-cell:not(".js-compare-product-cell")').eq(1).css('border-right', 'none');
            }
            if (step === 2) {
                $('.js-compare-table-row .js-compare-table-cell:not(".js-compare-product-cell")').eq(1).css('border-right', '1px solid #979797');
            }
        }
        toggleArrows(tilesLength, step, tilesOnPage)
}

/**
 * Creating object with data for compare product
 * @param {Object} $this - jQuery checkbox object
 * @param {boolean} checked - checked flag
 */
function createCompareProductObj($this, checked) {
    var pid = $this.attr('id');

    if (imgSrc === undefined) {
        var imgSrc = $this.closest('.modal-content').find('.placeholder .placeholder').prop('src');
        var productName = $this.closest('.modal').parent().find('.js-product-tile-name').html();
        var familyName = $this.closest('.modal').parent().find('.js-product-tile-family-name').html();
        var price = $this.closest('.modal').parent().find('.js-product-tile-price').html();
    } else {
        var imgSrc = $this.closest('.js-product-tile').find('.tile-image').prop('src');
        var productName = $this.closest('.js-product-tile').find('.js-product-tile-name').html();
        var familyName = $this.closest('.js-product-tile').find('.js-product-tile-family-name').html();
        var price = $this.closest('.js-product-tile').find('.js-product-tile-price').html();
    }
    //check if back button has already activated the tray
    if ($('.compare-bar-wrapper').css('display') === 'block'){
        var executed = true;
    } else {
        var executed = false;
    }

    var url = $this.parent().data('href');
    if (location.hash && !executed) {
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            success:function (data) {
                imgSrc = data.product.images.small[0].absURL;
                productName = data.product.productName;
                familyName = data.product.familyName;
                if(data.product.price.list !== null) {
                    price = data.product.price.list
                } else {
                    price = data.product.price.sales.formatted
                }
                if (checked) {
                    productsForComparison = selectProduct(productsForComparison, pid, imgSrc, productName, familyName, price);
                    $this.trigger('compare:selected', { pid: pid });
                } else {
                    productsForComparison = deselectProduct(productsForComparison, pid);
                    $this.trigger('compare:deselected', { pid: pid });
                }
            }
        });
    } else {
        if (checked) {
            if (imgSrc === undefined) {
                imgSrc = $this.closest('.modal-content').find('img:nth-of-type(1)').prop('src');
                
                productName = $this.closest('.js-product-tile').find('.js-product-tile-name').html();
                familyName = $this.closest('.js-product-tile').find('.js-product-tile-family-name').html();
                price = $this.closest('.js-product-tile').find('.js-product-tile-price').html();
                if (imgSrc === undefined) {
                    imgSrc = $this.closest('.js-product-tile').find('.tile-image').prop('src');
                }
            } else {
                if ($('.carousel').hasClass('slick-initialized')) {
                    imgSrc = $this.closest('.modal-content').find('img:nth-of-type(1)').prop('src');
                } else {
                    imgSrc = $this.closest('.modal-content').find('.placeholder .placeholder').prop('src');
                }
            }
            productsForComparison = selectProduct(productsForComparison, pid, imgSrc, productName, familyName, price);
            $this.trigger('compare:selected', { pid: pid });
        } else {
            productsForComparison = deselectProduct(productsForComparison, pid);
            $this.trigger('compare:deselected', { pid: pid });
        }
    }
}
// Compare page sticky element tricks
// get the sticky element
if ($('.sticky-top').length > 0){
    const stickyDiv = document.querySelector('.sticky-top');
    const stickyDivScrollableParent = getScrollParent(stickyDiv);
    setStickyTopLocation(stickyDiv);
    // stickiness has begun.
    stickyDiv._originalOffsetTop = stickyDiv.offsetTop;
    // compare scrollTop
    const detectStickiness = (theElement, cb) => () => cb & cb(theElement.offsetTop != theElement._originalOffsetTop)

    const onSticky = isSticky => {
        stickyDiv.classList.toggle('isSticky', isSticky)
    }

    const scrollCallback = throttle(detectStickiness(stickyDiv, onSticky), 100);
    stickyDivScrollableParent.addEventListener('scroll', scrollCallback);
    window.addEventListener('resize', function(event) { 
        setStickyTopLocation(stickyDiv); 
    }, true);
}

function getScrollParent(element, includeHidden) {
    var style = getComputedStyle(element),
        excludeStaticParent = style.position === "absolute",
        overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position !== "fixed") 
    for (var parent = element; (parent = parent.parentElement); ){
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") 
            continue;
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) 
            return parent;
    }

    return window
}
function setStickyTopLocation(element){
    const pencilBanner = document.querySelector('.header-banner');
    const mainHeader = document.getElementById('header-main');
    element.style.top = pencilBanner.offsetHeight + mainHeader.offsetHeight + "px";
}
// Throttle to prevent frequent invocations
function throttle (callback, limit) {
    var wait = false;                  
    return function () {               
        if (!wait) {                   
            callback.call();           
            wait = true;               
            setTimeout(function () {   
                wait = false;          
            }, limit);
        }
    }
}

/**
 * retrieve and create array of all product attributes to compare
 * @param {string} specCategory - compare category object name
 */
function getComponents(specCategory){
    var components = {}
    productsArr.forEach((product, productIndex) => {
        [specCategory].forEach(specType => {
            for (var key in product[specType]) {
                
                if (!components[key]) {
                    components[key] = {
                        name: product[specType][key].name,
                        specs: Array(productsArr.length).fill(null)
                    };
                }
                if (product[specType][key].specs === 'N/A'){
                    components[key].specs[productIndex] = { displayValue: product[specType][key].name, value: '-', id: product.id};
                } else if (product[specType][key].specs){
                    components[key].specs[productIndex] = { displayValue: product[specType][key].name, value: product[specType][key].specs, id: product.id };
                    
                } else {
                    components[key].specs[productIndex] = { displayValue: product[specType][key].name, value: '-', id: product.id };
                }
            }
        });
        
    });
    return components;
}

/**
 * Adds compare table for each section
 * @param {Object} specComponents - object attribute compare category
 * @param {string} specCategory - compare group title
 * @param {string} specSectionID - compare group element id
 */

function addCompareSpecs(specComponents, specCategory, specSectionID){
    var tableContainer = document.getElementById('comparisonTable');
    var specsLength = Object.keys(specComponents).length;
    var viewMoreHTML = '<div class="compare-view-more">';
    viewMoreHTML += '<button type="button" class="btn btn-text compare-view-more__btn js-view-more"><div class="more-label">';
    viewMoreHTML += 'View more';
    viewMoreHTML += '</div><div class="less-label">';
    viewMoreHTML += 'View less';
    viewMoreHTML += '</div></button></div>';

    var index = 0;
    if (specsLength > 0){
        
        for (var component in specComponents) {
            
            var allNA = specComponents[component].specs.every(spec => spec.value === '-');
            specsLength--;
            if (!allNA) {
                
                if (index == 0){
                    var headerRow = document.createElement('div');
                    headerRow.className = 'compare-table__charact-header';

                    var div = document.createElement('h3');
                    div.className = 'compare-table__heading';
                    div.textContent = specCategory;
                    headerRow.appendChild(div);

                    tableContainer.appendChild(headerRow);

                    var bodyRow = document.createElement('div');
                    bodyRow.className = 'compare-table__body';
                    bodyRow.id = specSectionID;
                    tableContainer.appendChild(bodyRow);
                
                    // Build the rows dynamically
                }
               
                var row = document.createElement('div');
                row.className = 'compare-table__row';

                var rowHeading = document.createElement('div');
                rowHeading.className = 'compare-table__cell compare-table__cell-heading';
                rowHeading.textContent = specComponents[component].name
                row.appendChild(rowHeading);
                
                document.getElementById(specSectionID).appendChild(row);

                var rowAttributes = document.createElement('div');
                rowAttributes.className = 'compare-table__row js-compare-table-row';
    
                specComponents[component].specs.forEach(spec  => {
                    
                    var cellBlankHeader = document.createElement('div');
                    cellBlankHeader.className = 'compare-table__cell compare-table__cell-heading js-compare-table-cell';
                    cellBlankHeader.dataset.id = spec.id;
                    cellBlankHeader.textContent = '';
                    row.appendChild(cellBlankHeader);

                    var cellValue = document.createElement('div');
                    cellValue.className = 'compare-table__cell js-compare-table-cell';
                    cellValue.innerHTML = spec.value;
                    cellValue.dataset.id = spec.id;
                    cellValue.dataset.compare = spec.value;
                    rowAttributes.appendChild(cellValue);
                });

                if (index == 5){
                    var hiddenRow = document.createElement('div');
                    hiddenRow.className = 'hidden-rows js-hidden-rows ' + specSectionID+"-attributes";
                    document.getElementById(specSectionID).appendChild(hiddenRow);
                    hiddenRow.appendChild(row);
                    hiddenRow.appendChild(rowAttributes);
                   
                } else if (index >= 6){
                    hiddenRow.appendChild(row);
                    hiddenRow.appendChild(rowAttributes);
                   
                } else{
                    document.getElementById(specSectionID).appendChild(row);
                    document.getElementById(specSectionID).appendChild(rowAttributes);
                }
                
                index++;
            }
            if (index >= 6 && specsLength == 0){
                // document.getElementById(specSectionID).append += viewMoreHTML;
                document.getElementById(specSectionID).insertAdjacentHTML(
                    'beforeend',
                    viewMoreHTML,
                  );
            }
        }
    }
}
function updateEditLink(){
    let editSelection = document.querySelector('a.compare-page__back');
    const urlObj = new URL(location);
    // Get the search parameters
    const searchParams = new URLSearchParams(urlObj.search);

    // Filter and map the parameters that start with 'pid'
    const pidValues = Array.from(searchParams.entries())
    .filter(([key]) => key.startsWith('pid'))
    .map(([, value]) => value);

    if (editSelection) {
        editSelection.href += "#"+ pidValues.join(',');
    }
}
Compare.createCompareTable = function (){
    if ( $('#comparisonTable').length >0 ) {
        var keyComponents = getComponents('keySpecs');
        var genComponents = getComponents('genSpecs');
        addCompareSpecs(keyComponents, 'Key Specs', 'keySection');
        addCompareSpecs(genComponents, 'General Specs', 'genSection');
        // Compare.showDifferentCharacteristic();
        updateEditLink();
    }
}
Compare.quantityOfProductItems = function () {
    var tilesLength = $('.js-compare-product-cell').length;
    $('.js-compare-table, .js-compare-tile').addClass('m-width-'+tilesLength);
    if (tilesLength < 3) {
        $('.js-compare-btn-container').hide();
    }
}

Compare.touchSwipe = function () {
    var clientSwipeEnd = 0;
    var clientSwipeStart = 0;

    $('.js-compare-tile').on('touchstart', function(e) {
        clientSwipeStart = parseInt(e.targetTouches[0].pageX.toFixed(0));
        clientSwipeEnd = parseInt(e.targetTouches[0].pageX.toFixed(0));
    });
    $('.js-compare-tile').on('touchmove', function(e) {
        clientSwipeEnd = parseInt(e.targetTouches[0].pageX.toFixed(0));
        timer = setTimeout(function () {
            clientSwipeStart = parseInt(e.targetTouches[0].pageX.toFixed(0));
        }, 500);
    });
    $('.js-compare-tile').on('touchend', function(e) {
        clearTimeout(timer);
        var $this = $(this);
        swipeTranslateTable($this, clientSwipeStart, clientSwipeEnd);
    });
}

Compare.showDifferentCharacteristic = function () {
    var $tableRows = $('.js-compare-table-row');
    var dataArray = [];

    $tableRows.each(function (index, tableRow) {
        dataArray = [];
        $(tableRow).find('.js-compare-table-cell:not(.js-compare-product-cell)').map(function(ind,item) {
            dataArray.push($(item).data('compare'));
            return true;
        });

        const isSame = (dataArray) => dataArray.every(item => item === dataArray[0]);

        // Check if all items are the same
        // const result = areItemsSame(array);

        $(tableRow).removeClass('m-common-characteristic');

        if (dataArray.length > 0) {
           if(isSame(dataArray)) $(tableRow).addClass('m-common-characteristic');
        }
    });    
};

Compare.toggleHighlightCompare = function () {
    $('.js-compare-toggler').on('change', function () {
        var $table = $('.js-compare-table');
        var isEnabled = $(this).prop('checked');
        if (isEnabled) {
            $table.addClass('m-highlight-compare');
        } else {
            $table.removeClass('m-highlight-compare');
        }
        Compare.showDifferentCharacteristic();
    });
};

Compare.removeComparedProduct = function () {
    $('.js-compare-remove').on('click', function () {
        var $product = $('.js-compare-product-cell');
        var productId = $(this).data('id');

        if ($product.length === 1) {
            $('.js-compare-table-cell').remove();
        } else {
            $('.js-compare-table-heading[data-id="' + (productId) + '"]').remove();
            $('.js-compare-table-cell[data-id="' + productId + '"]').remove();
        }
        $('.js-product-counter').html($product.length - 1);
        changePageLocationAddress(productId);
        Compare.quantityOfProductItems();
        Compare.showDifferentCharacteristic();
    });
};

Compare.toggleCharacteristics = function () {
    $('#keySection .js-view-more').on('click', function () {
        if ($(this).is('.m-active')) {
            $('.keySection-attributes.js-hidden-rows').slideUp();
            $(this).removeClass('m-active');
        } else {
            $('.keySection-attributes.js-hidden-rows').slideDown();
            $(this).addClass('m-active');
        }
    });
    $('#genSection .js-view-more').on('click', function () {
        if ($(this).is('.m-active')) {
            $('.genSection-Section-attributes.js-hidden-rows').slideUp();
            $(this).removeClass('m-active');
        } else {
            $('.genSection-attributes.js-hidden-rows').slideDown();
            $(this).addClass('m-active');
        }
    });
};

Compare.moveCompareTable = function () {
    $('.js-compare-arrow').on('click', function () {
        var $this = $(this);
        var step = parseInt($this.closest('.js-compare-tile').data('step'));
        var productLength = $('.js-compare-product-cell').length;
        var $tableRows = $('.js-compare-table-row');

        if ($this.is('.m-next') && step < (productLength - tilesOnPage)) {
            step++;
            moveTableRow($this, $tableRows, step);
        } else if ($this.is('.m-prev') && step > 0) {
            step--;
            moveTableRow($this, $tableRows, step);
        }
        toggleArrows(productLength, step, tilesOnPage);
    });
};

Compare.selectCheckedProducts = function () {
    $('.product-grid').ready(function () {
        if (location.hash) {
            location.hash.replace('#', '').split(',').forEach(function (id) {
                if($('input#' + id).length) {
                    $('input#' + id).prop('checked', 'checked');
                } else {
                    $('input.js-compare-checkbox').each(function (){
                        // if ($('input[data-variants*="' + id +'"]')){
                        if ($(this).is('[data-variants*="' + id +'"]')){
                            var uselessId = $(this).attr('id');
                            var currTileUrl = $(this).parent().attr('href');
                            var updateCurrUrl = currTileUrl.replace(uselessId, id);
                            $(this).parent().attr('href', updateCurrUrl);
                            $(this).parent().attr('data-href', updateCurrUrl);
                            $(this).attr('id', id);
                            $('input#' + id).parent().find('.js-compare-modal-btn').attr('id', id).prop('checked', 'checked');
                            // $('input#' + id).prop('checked', 'checked');
                        }
                    });
                }
            });
        }
        $('.compare input:checked').each(function () {
            var $this = $(this);
            var checked = true;
            createCompareProductObj($this, checked);
        });
    });
};

Compare.handleCompareClick = function () {
    $('div.page').on('change', '.compare input[type=checkbox]', function () {
        var $this = $(this);
        var checked = $(this).is(':checked');
        createCompareProductObj($this, checked);
    });
};

Compare.handleClearAll = function () {
    $('.compare-bar a.clear-all').on('click', function (e) {
        e.preventDefault();
        clearCompareBar();
    });
};

Compare.catchFilterChange = function () {
    $('.container').on('click', '.refinements li:not(.js-cat-li) a, .refinement-bar a.reset', function (e) {
        e.preventDefault();
        clearCompareBar();
    });
};

Compare.deselectProductOnCompareBar = function () {
   $('.compare-bar').on('click', '.close', function () {
       var pid = $(this).closest('.slot').data('pid').toString();
       productsForComparison = deselectProduct(productsForComparison, pid);
       $(this).trigger('compare:deselected', { pid: pid });
   });
};

module.exports = Compare;
